<template>
  <div>
    <!-- Table Container Card -->
    <emergency-degree-add-new
      :is-add-emergency-degree-sidebar-active.sync="
        isAddEmergencyDegreeSidebarActive
      "
      @refetch-data="refetchData"
      v-if="isAddEmergencyDegreeSidebarActive"
    />

    <edit-emergency-degree
      :is-edit-emergency-degree-sidebar-active.sync="
        isEditEmergencyDegreeSidebarActive
      "
      :emergency-degree="emergencyDegreeRef"
      @refetch-data="refetchData"
      v-if="isEditEmergencyDegreeSidebarActive"
    />
    <div style="display: flex; justify-content: flex-end; margin: 20px 0">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="isAddEmergencyDegreeSidebarActive = true"
        variant="primary"
      >
        {{ $t("Add wording") }}
      </b-button>
    </div>
    <b-card no-body class="mb-0">
      <b-table
        ref="refEmergencyDegreeListTable"
        class="position-relative"
        :items="fetchEmergencyDegree"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!----<b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="setEmergencyDegree(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t("Edit") }}</span>
            </b-dropdown-item>
          </b-dropdown>-->
          <b-button
            @click="setEmergencyDegree(data.item)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon mr-1"
          >
            <feather-icon icon="Edit2Icon" size="16" />
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalEmergencyDegree"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import userEmergencyDegreeList from "./useWordingList";
import { ref } from "@vue/composition-api";
import EmergencyDegreeAddNew from "./WordingAddNew.vue";
import EditEmergencyDegree from "./EditWording.vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    EmergencyDegreeAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    EditEmergencyDegree,

    vSelect,
  },

  setup(props) {
    const isAddEmergencyDegreeSidebarActive = ref(false);
    const isEditEmergencyDegreeSidebarActive = ref(false);
    const emergencyDegreeRef = ref({});

    const {
      fetchEmergencyDegree,
      tableColumns,
      perPage,
      currentPage,
      totalEmergencyDegree,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEmergencyDegreeListTable,
      refetchData,
    } = userEmergencyDegreeList();

    function setEmergencyDegree(EmergencyDegree) {
      isEditEmergencyDegreeSidebarActive.value = true;
      emergencyDegreeRef.value = EmergencyDegree;
    }

    return {
      // Sidebar
      isAddEmergencyDegreeSidebarActive,
      isEditEmergencyDegreeSidebarActive,
      fetchEmergencyDegree,
      tableColumns,
      perPage,
      currentPage,
      totalEmergencyDegree,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEmergencyDegreeListTable,
      refetchData,
      setEmergencyDegree,
      emergencyDegreeRef,
      // Filter
      avatarText,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
